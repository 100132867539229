var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',[_c('CToaster',{attrs:{"autohide":3000}},[_vm._l((_vm.infoList),function(info){return [_c('CToast',{key:info.message,attrs:{"show":true,"header":info.header,"color":info.color}},[_vm._v(" "+_vm._s(info.message)+". ")])]})],2)],1),_c('div',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_vm._v(" Availability ")]),_c('CCardBody',[_c('CRow',[_c('CCol',[_c('CSelect',{attrs:{"value":_vm.selectedProductId,"options":_vm.rentalProducts,"label":"Select Rental Product"},on:{"update:value":function($event){_vm.selectedProductId=$event},"change":_vm.onSelectRentalProduct}})],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"8"}},[_c('calendar-view',{ref:"calendarView",class:_vm.themeClasses,attrs:{"id":"calendar","events":_vm.events,"enable-drag-drop":"","show-date":_vm.showDate,"displayPeriodUom":_vm.displayPeriod,"currentPeriodLabel":"icons","day-classes":_vm.dayClasses},on:{"click-date":_vm.onClickDay,"click-event":_vm.onClickEvent,"drop-on-date":_vm.onDrop,"show-date-change":_vm.setShowDate},scopedSlots:_vm._u([{key:"header",fn:function({ headerProps }){return _c('calendar-view-header',{attrs:{"header-props":headerProps},on:{"input":_vm.setShowDate}})}}])})],1),_c('CCol',{attrs:{"sm":"4"}},[_c('CForm',[_c('CInput',{attrs:{"type":"date","placeholder":"Start Date"},model:{value:(_vm.availability.startDate),callback:function ($$v) {_vm.$set(_vm.availability, "startDate", $$v)},expression:"availability.startDate"}}),_c('CInput',{attrs:{"type":"date","placeholder":"End Date"},model:{value:(_vm.availability.endDate),callback:function ($$v) {_vm.$set(_vm.availability, "endDate", $$v)},expression:"availability.endDate"}}),_c('CSelect',{attrs:{"value":_vm.availability.status,"options":this.statuses,"label":"Status"},on:{"update:value":function($event){return _vm.$set(_vm.availability, "status", $event)}}}),_c('CBadge',{style:({
                      backgroundColor: _vm.selectedStatusColor,
                      color: '#fff',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      display: 'inline-block',
                      marginTop: '5px',
                      width: '100%',
                      height: '100px',
                    })},[_vm._v(" "+_vm._s(_vm.selectedStatusLabel)+" ")]),_c('CInput',{attrs:{"label":"Price","type":"number","placeholder":"Price"},model:{value:(_vm.availability.price),callback:function ($$v) {_vm.$set(_vm.availability, "price", $$v)},expression:"availability.price"}}),_c('CButton',{attrs:{"color":"primary"},on:{"click":_vm.saveAvailability}},[_vm._v("Save Availability")])],1)],1)],1)],1),_c('CCardFooter',[_c('CForm',{attrs:{"inline":""}},[_c('CSelect',{attrs:{"options":['year', 'month', 'week'],"value":_vm.displayPeriod,"label":"View","add-wrapper-classes":"ml-2"},on:{"update:value":function($event){_vm.displayPeriod=$event}}}),_c('h4',{staticClass:"ml-auto my-auto"},[(_vm.message)?_c('CBadge',{attrs:{"color":"primary"}},[_vm._v(_vm._s(_vm.message))]):_vm._e()],1)],1)],1)],1)],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }